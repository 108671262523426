import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createTenant, PostTenantsRequest } from '../../libs/api';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

type FormType = PostTenantsRequest;
const Page: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({
    mode: 'onBlur',
  });

  const onSubmit = (data: FormType) =>
    createTenant(data)
      .then(() => {
        navigate('/tenants', { replace: true });
      })
      .catch((e) => {
        setError(e.message);
      });

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        Add new tenant
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            name="tenantId"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label="ID"
                {...field}
                helperText="テナントを識別する文言を入力してください。システムの内部的に使用されます。変更はできません。 例) abchotel"
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label="名称"
                {...field}
                helperText="テナントの名称を入力してください。 例) ABCホテル"
              />
            )}
          />
          <Controller
            name="tenantAdminEmail"
            control={control}
            render={({ field }) => (
              <TextField
                required
                label="テナント管理者Email"
                helperText="このテナント管理者のEmailを入力してください。変更できません。 例) admin@example.com"
                {...field}
              />
            )}
          />
          <Controller
            name="tenantAdminTemporaryPassword"
            control={control}
            rules={{
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
                message: '8文字以上99文字以下で英数字記号を組み合わせてください',
              },
            }}
            render={({ field }) => (
              <TextField
                // 文字列があった場合にはtrueとします。
                error={!!errors.tenantAdminTemporaryPassword}
                required
                type="password"
                helperText={
                  errors.tenantAdminTemporaryPassword?.message ||
                  'このテナント管理者が最初にログインする為のパスワードを入力してください。テナント管理者は最初のログイン時にパスワードの変更を求められます。'
                }
                label="テナント管理者一時パスワード"
                {...field}
              />
            )}
          />
          <Stack direction="row">
            <Button type="submit" variant="contained" loading={isSubmitting}>
              作成
            </Button>
          </Stack>
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>サーバーエラー</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
