import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { updateTenant, findTenant, Tenant } from '../../libs/api';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

type FormType = Omit<Tenant, 'tenantId'>;

const Page: React.FC = () => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string>();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormType>();

  React.useEffect(() => {
    if (!tenantId) return;
    findTenant({ tenantId })
      .then(({ name, tenantAdminEmail }) => {
        reset({
          name,
          tenantAdminEmail,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tenantId]);

  const onSubmit = (data: Tenant) =>
    updateTenant(data)
      .then(() => {
        navigate('/tenants', { replace: true });
      })
      .catch((e) => {
        setError(e.message);
      });

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        Update
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <TextField disabled label="ID" value={tenantId} />
          <Controller name="name" control={control} render={({ field }) => <TextField label="Name" {...field} />} />
          <Controller
            name="tenantAdminEmail"
            control={control}
            render={({ field }) => <TextField disabled label="Tenant Manager Email" {...field} />}
          />
          <Stack direction="row">
            <Button type="submit" variant="contained" loading={isSubmitting}>
              Update
            </Button>
          </Stack>
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>Server Error</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
